<template>
  <l-map ref="map" style="height: 600px" :center="center" v-if="markers.length > 0">
    <l-tile-layer :url="url"/>
    <l-feature-group ref="features">
      <l-marker :lat-lng="marker.latlng" v-for="marker in markers" :key="marker.key" @click="onMarkerClick(marker)"/>
      <l-popup ref="popup">
        <slot></slot>
      </l-popup>
    </l-feature-group>
  </l-map>
</template>

<script>
import {LMap, LTileLayer, LMarker, LPopup, LFeatureGroup} from 'vue2-leaflet';

// Snipper fix icons: https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing
import {Icon} from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  name: "MapCluster",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LFeatureGroup,
    LPopup
  },
  props: {
    clusters: {
      type: Array,
      required: true
    },
  },
  emits: ['click'],
  computed: {
    markers() {
      return this.clusters.map(point => {
        return {
          latlng: [point.gps_latitude * 1, point.gps_longitude * 1],
          id: `${point.id}`,
        }
      })
    },
    center() {
      // Average lat e lng for every point
      let sumLat = 0
      let sumLng = 0
      this.markers.forEach(point => {
        sumLat += point.latlng[0]
        sumLng += point.latlng[1]
      })

      return [sumLat / this.markers.length, sumLng / this.markers.length]
    },
  },
  watch: {
    markers() {
      this.setZoomBasedOnMarkers()
    }
  },
  async mounted() {
    await this.$nextTick()
    this.features = this.$refs.features.mapObject
    this.map = this.$refs.map.mapObject
    this.setZoomBasedOnMarkers()
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      selected: null,
      features: null,
      map: null,
    }
  },
  methods: {
    async onMarkerClick(marker) {
      const open = await this.emitPromised('click', marker.id)
      if (open) this.features.openPopup(marker.latlng)
    },
    setZoomBasedOnMarkers() {
      this.map.fitBounds(this.features.getBounds())
    },
  },

}
</script>

<style scoped>

</style>