<template>
  <div v-if="!loading">
    <div v-if="isGenerated">
      <PSelectKeyValue :value="clusterTypeId" :options="clusterTypes" @input="onChangeClusterType"/>
      <MapCluster :clusters="clusters" @click="onPointClick" v-if="clusters">
        <div style="width: 500px; height: 100px; max-width: 500px; overflow: auto">
          Stats: {{stats}}
        </div>

      </MapCluster>
      <div v-if="clusterId">
        <div>
          TAGS: {{ tags }}
          <PTagInput v-model="form.tags" addable/>
          <CButton color="primary" @click="onClickUpdateTags" v-translate translate-context="event_wizard">
            Save
          </CButton>
        </div>
        <div>
          MEDIA:{{ media }}
        </div>
      </div>

    </div>
    <div v-else>
      The map is not generated yet
    </div>
    <PButton :loading="generating" class="btn btn-primary btn-sm ml-2 mt-3" loading-text="Generating"
             @click="onGenerateClick">Force Generation
    </PButton>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MapCluster from "@/domain/core/components/mapCluster/MapCluster.vue";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";
import PButton from "@/components/PButton.vue";
import PTagInput from "@/domain/core/components/PTagInput.vue";

export default {
  name: 'PhotoMap',
  components: {PTagInput, PButton, PSelectKeyValue, MapCluster},
  data() {
    return {
      loading: true,
      generating: false,
      form: {
        tags: []
      }
    }
  },
  watch: {
    tags(newTags) {
      this.form.tags = newTags
    }
  },
  async mounted() {
    this.setPicaServiceId(this.$route.params.picaServiceId)
    this.setClusterTypeId(null)
    await this.fetchTypes()
    this.loading = false
  },
  computed: {
    ...mapGetters('photoMap', [
      'isGenerated',
      'types',
      'clusters',
      'clusterTypeId',
      'clusterId',
      'media',
      'tags',
      'stats'
    ]),
    clusterTypes() {
      if (!this.types) return []
      const types = this.types.map(type => {
        return {
          key: type.id,
          value: type.name
        }
      })
      return [{
        key: null,
        value: 'All'
      },...types]
    }
  },
  methods: {
    ...mapActions('photoMap', [
      'setPicaServiceId',
      'setClusterTypeId',
      'setClusterId',
      'fetchTypes',
      'fetchClusters',
      'fetchMedia',
      'fetchTags',
      'fetchStats',
      'updateTags',
      'forceGeneration',
    ]),
    async onChangeClusterType(newType) {
      this.setClusterTypeId(newType.value)
      await this.fetchClusters()
    },
    onPointClick(id) {
      this.setClusterId(id)
      this.fetchMedia()
      this.fetchTags()
      this.fetchStats()
    },
    onClickUpdateTags() {
      this.updateTags(this.form.tags)
    },
    async onGenerateClick() {
      this.generating = true
      await this.forceGeneration()
      this.generating = false
    }

  }
}
</script>